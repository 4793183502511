import React from 'react'
import styled from 'styled-components'
import {About} from "../styles";
import Toggle from './Toggle';
import {AnimateSharedLayout} from 'framer-motion'
import {useScroll} from './useScroll';
import {fade} from '../animation'

const FaqSection = () => {
    const [element,controls] = useScroll();
    return(
        <Faq variants ={fade} ref={element} animate={controls} initial="hidden">
            <h2>Any Questions<span>FAQ</span></h2>
            <AnimateSharedLayout>
            <Toggle title="Where can we find your church at?">
                <div className="answer">
                <p>We currently are gathering together virtually via YouTube.</p>
                </div>
            </Toggle>
            <Toggle title="Do you have a women's ministry?">
                <div className="answer">
                <p>Our purpose is to bring women of all ages together for fellowship and growth and to reach out to our friends and family with the message of God's love as demonstrated through Jesus' life, death and resurrection.</p>
            </div>
            </Toggle>
            <Toggle title="How long have you been a church for?">
                <div className="answer">
                    <p>We have been a church for over 15 years.</p>
                </div>
            </Toggle>
            </AnimateSharedLayout>
        </Faq>
        
    )
}

const Faq = styled(About) `
    display:block;
    span{
        display:block;

    }
    h2{
        padding-bottom:2rem;
        font-weight:lighter;
        color:#282828;
    }
    .faq-line{
        background:#cccccc;
        height:0.2rem;
        margin: 2rem 0rem;
        width:100%;
    }
    .question{
        padding:3rem 0rem;
        cursor:pointer;
    }
    .answer{
        padding:2rem 0rem;
        p{
            padding:1rem 0rem;
        }
    }

`;
export default FaqSection;