import React from 'react'
import home1 from '../img/home1.png'
//Styled
// import styled from 'styled-components';
import {About, Description,Image,Hide} from '../styles';
//framer motion
import {motion} from 'framer-motion'
//Wave
import Wave from './Wave';


const AboutSection = () => {

    const titleAnim = {
        hidden: {opacity:0},
        show:{opacity: 1,transition:{duration:2}}
    }

    const container = {
        hidden: {x: 100},
        show: {x: 0,transition:{duration:0.75,ease: "easeOut", staggerChildren: 1, when:"beforeChildren"}},
    }
    return(
        <About>
            <Description>
                <motion.div variants={container} initial = "hidden" animate= "show" className="title">
                    <Hide>
                        <motion.h2 variants={titleAnim}>All are <span>Welcome.</span></motion.h2>
                    </Hide>
                    <Hide>
                        <motion.h2 variants={titleAnim}>All are <span>Loved.</span>
                        </motion.h2>
                    </Hide>
                    <Hide>
                        <motion.h2 variants={titleAnim}>Welcome <span>Home.</span></motion.h2>
                    </Hide>
                </motion.div>
            </Description>
            <Image>
                <img src={home1} alt="guy with camera"/>
            </Image>
            <Wave/>
        </About>
    )
}


export default AboutSection;