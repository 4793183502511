import React from 'react'
import ContactSection from '../components/ContactSection'

const ContactUs = () => {
    return(
        <div>
            <ContactSection/>
        </div>
    )
}
export default ContactUs;