import React from 'react'

//import icons 
import phone from '../img/telephone.png'
import email from '../img/gmail.png'
import pastors from '../img/pastors.jpg'

//Styles
import {About, Description,Image} from '../styles';
import styled from "styled-components"

const ContactSection = () =>{
    return(
        <Services>
            <Description>
                <h2>Contact<span> Us</span></h2>
                <Cards>
                    <Card>
                      <div className="icon">
                        <img alt = "email" src={email}/>
                        <h3>Email</h3>
                    </div>
                    <p>powerinpraiseministries408@gmail.com</p>
                    </Card>
                    <Card>
                      <div className="icon">
                        <img alt = "phone" src={phone}/>
                        <h3>Phone</h3>
                    </div>
                    <p>408-705-0595</p>
                    </Card>

                
                
                </Cards>
            </Description>
            <Image>
                <img src={pastors} alt="pastors"/>
                <Info>
                <h3>Moses and Dorothy Mariscal have been pastors in Gilroy for over thirty years. They recently celebrated forty three years of marriage. They have two children who are also involved in their home church’s. Moses enjoys learning about history and gardening. Dorothy enjoys singing and writing.  </h3>  
                </Info>
            </Image>
        </Services>

    )
}


const Services = styled(About)`
h2{
    padding-bottom: 5rem;
}
p{
    width:70%;
    padding:2rem 0rem 4rem 0rem;
}
`;
const Cards = styled.div`
    display: flex;
    flex-wrap:wrap;
`;

const Card = styled.div`
    flex-basis: 20rem;
    .icon{
        display: flex;
        align-items: center;
        h3{
            margin-left: 1rem;
            background: white;
            color: black;
            padding: 1rem;
        }
    }
`


const Info = styled.div`
        h3{
            color: black;
            font-size:2rem;
            padding: 3rem;
            font-family: 'Inter', sans-serif
        }
`;



export default ContactSection