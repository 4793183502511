import React from 'react'
//import icons 
import home2 from '../img/home2.png'
import pray from '../img/prayhand.png'
import people from '../img/people.png'
import heart from '../img/heart.png'
import fruit from '../img/fruit.png'
//Styles
import {About, Description,Image} from '../styles';
// import AboutUs from '../pages/AboutUs'
import styled from "styled-components"
// import {scrollReveal} from '../animation'
import {useScroll} from './useScroll';



const ServicesSection = () =>{
    const [element,controls] = useScroll();
    return(
        <Services  animate={controls} initial="hidden" ref={element}>
            <Description>
                <h2>Core <span>Values</span></h2>
                <Cards>
                    <Card>
                      <div className="icon">
                        <img alt = "pray" src={pray}/>
                        <h3>Prayer</h3>
                    </div>
                    <p>But truly God has listened; he has attended to the voice of my prayer.Psalms 66:19</p>
                    </Card>
                    <Card>
                      <div className="icon">
                        <img alt = "teamwork" src={people}/>
                        <h3>Fellowship</h3>
                    </div>
                    <p>Therefore encourage one another and build one another up, just as you are doing.1 Thessalonians 5:11</p>
                    </Card>
                    <Card>
                      <div className="icon">
                        <img alt ="heart" src={heart}/>
                        <h3>Love</h3>
                    </div>
                    <p>And over all these virtues put on love, which binds them all together in perfect unity.Colossians 3:14</p>
                    </Card>
                    <Card>
                      <div className="icon">
                      <img alt = "fruit" src={fruit}/>
                        <h3>Fruitfulness</h3>
                    </div>
                    <p>This is to my Father’s glory, that you bear much fruit, showing yourselves to be my disciples.John 15:8</p>
                    </Card>
                </Cards>
            </Description>
            <Image>
                <img src={home2} alt="camera"/>
            </Image>
        </Services>

    )
}


const Services = styled(About)`
h2{
    padding-bottom: 5rem;
}
p{
    width:70%;
    padding:2rem 0rem 4rem 0rem;
}
`;
const Cards = styled.div`
    display: flex;
    flex-wrap:wrap;
`;

const Card = styled.div`
    flex-basis: 20rem;
    .icon{
        display: flex;
        align-items: center;
        h3{
            margin-left: 1rem;
            background: #6C83A5;
            color: white;
            padding: 1rem;
        }
    }
`
export default ServicesSection